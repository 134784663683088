import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import truck from "../assets/truck.svg";

const NotFound = ({ isServiceAvailable = true }) => {
  const { t } = useTranslation();

  return (
    <section className="md:my-6 md:bg-white rounded-2xl md:shadow-app  max-w-screen-md mx-auto flex flex-col items-center  gap-10 md:p-10 p-6">
      <img alt="" src={truck} className="w-[14.813rem]  md:order-last " />
      <h1 className="text-2xl leading-8 font-semibold ">
        {isServiceAvailable
          ? t("urlInvalid.title")
          : t("serviceUnavailable.title")}
      </h1>

      <div>
        {isServiceAvailable ? (
          <p>
            {t("urlInvalid.description")}
            <a
              href="https://vportal.zendesk.com"
              target="_blank"
              rel="noreferrer"
              className="text-app-blue underline cursor-pointer"
            >
              support
            </a>
            .
          </p>
        ) : (
          <p>{t("serviceUnavailable.description")}</p>
        )}
      </div>
    </section>
  );
};
export default NotFound;
