import React from "react";
import { Oval } from "react-loader-spinner";

const Loader = ({
  color = "#1A1F71",
  size = 0.25,
  className = "",
  secondaryColor = "#ddd",
  responsive = false,
}) => {
  const dynamicSize = Math.min(window.innerWidth, window.innerHeight) * size;

  return (
    <div
      className={`${
        !responsive && "fixed"
      } inset-0 flex items-center justify-center ${className}`}
    >
      <Oval
        color={color}
        height={dynamicSize}
        width={dynamicSize}
        secondaryColor={secondaryColor}
      />
    </div>
  );
};

export default Loader;
