import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { CiCircleInfo } from "react-icons/ci";
import { GoChevronDown } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import toast from "react-hot-toast";

import i18n from "../../i18n";

import Info1Img from "../../assets/onboarding/info-1.png";
import Info2Img from "../../assets/onboarding/info-2.png";
import Info3Img from "../../assets/onboarding/info-3.png";
import WearableImg from "../../assets/onboarding/wearable.png";
import CardImg from "../../assets/onboarding/card.png";
import { useSelector } from "react-redux";
import API from "../../services/api";
import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";

const formErrors = {
  DEVICE_NUMBER: "onboarding.form.validation.wearable-device-number",
  CARD_NUMBER: "onboarding.form.validation.card-device-number",
  DEVICE_UNKNOWN: "onboarding.form.validation.device-unknown",
  EMAIL_REQUIRED: "onboarding.form.validation.email-required",
  EMAIL_REQUIRED_CONFIRM: "onboarding.form.validation.email-required",
  EMAIL_MATCH: "onboarding.form.validation.email-match",
  INVALID_CODE: "onboarding.form.validation.invalid-code",
  INVALID_EMAIL: "onboarding.form.validation.invalid-email",
};
const Onboarding = () => {
  const navigate = useNavigate();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [formError, setFormError] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { device } = useSelector((state) => state.Auth);
  const { t } = useTranslation();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const uniqueHash = location.hash.substring(1);
      if (!uniqueHash) {
        return toast.error(
          "Device not recognized. Please scan the QR code again."
        );
      }

      if (
        (e.target["device-number"].value.length !== 7 &&
          device.type === "WALKIE") ||
        isNaN(e.target["device-number"].value)
      ) {
        return setFormError("DEVICE_NUMBER");
      }

      if (
        (e.target["device-number"].value.length !== 8 &&
          device.type === "WALKIE") ||
        isNaN(e.target["device-number"].value)
      ) {
        return setFormError("CARD_NUMBER");
      }

      if (!e.target.email.value) {
        return setFormError("EMAIL_REQUIRED");
      }

      if (!e.target["confirm-email"].value) {
        return setFormError("EMAIL_REQUIRED_CONFIRM");
      }

      if (e.target.email.value !== e.target["confirm-email"].value) {
        return setFormError("EMAIL_MATCH");
      }
      const deviceNumber = e.target["device-number"].value;

      if (device.activationCodeRequired && !e.target["activation-code"].value) {
        return setFormError("INVALID_CODE");
      }

      setLoading(true);
      const res = await API.post(`/user/signup`, {
        email: e.target.email.value,
        deviceNumber,
        activationCode: e.target["activation-code"]?.value,
        language: i18n.language,
      });
      setLoading(false);

      if (!res.ok) {
        if (res.errorCode === "INVALID_PROPERTY") {
          return setFormError("DEVICE_UNKNOWN");
        }
        if (res.errorCode === "INVALID_CODE") {
          return setFormError("INVALID_CODE");
        }
        if (res.errorCode === "INVALID_EMAIL") {
          return setFormError("INVALID_EMAIL");
        }
        return toast.custom(
          () => (
            <CustomToast
              toast={toast}
              title={t("generic-error.title")}
              description={t("generic-error.description")}
              status="GENERIC_ERROR"
            />
          ),
          {
            position: "top-right",
          }
        );
      }

      if (res.data.existingUser)
        return navigate(`/onboarding/terms-of-use#${uniqueHash}`, {
          replace: true,
        });

      navigate(`/onboarding/profile#${uniqueHash}`, {
        replace: true,
      });
    } catch (e) {
      console.log(e);
    }
  };
  if (loading) return <Loader />;

  return (
    <>
      <section className="md:my-6 md:bg-white rounded-2xl md:shadow-app  max-w-screen-md mx-auto flex flex-col md:flex-row items-center justify-center gap-10 md:p-10 p-6">
        <div className="flex-1 flex-col items-center justify-center md:gap-y-10 gap-y-6 hidden md:flex">
          <img
            src={device.isCard ? CardImg : WearableImg}
            alt=""
            className="rounded-lg"
          />
          <h2 className="font-bold leading-6 text-center">
            {device.isCard
              ? t("onboarding.card-title")
              : t("onboarding.wearable-title")}
          </h2>
        </div>
        <div className="flex-1 md:border-l md:pl-10 flex flex-col md:gap-y-10 gap-y-6">
          <div className="md:space-y-2 space-y-1.5">
            <h1 className="text-2xl leading-8 font-semibold">
              {t("onboarding.welcome")}
            </h1>
            <p className="text-sm leading-5">
              {t(
                device.isCard
                  ? "onboarding.description-card"
                  : "onboarding.description-wearable",
                { amount: device.topupAmount }
              )}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center md:gap-y-10 gap-y-2 md:hidden rounded-2xl">
            <img
              src={device.isCard ? CardImg : WearableImg}
              alt=""
              className="rounded-2xl w-[80%]"
            />
            <h2 className="leading-6 text-center text-xs flex items-center gap-x-2">
              <CiCircleInfo size={18} />
              {device.isCard
                ? t("onboarding.card-title")
                : t("onboarding.wearable-title")}
            </h2>
          </div>
          <form
            onSubmit={onSubmit}
            className="space-y-4 flex-1 flex flex-col"
            noValidate
          >
            <fieldset className="space-y-1.5">
              <label htmlFor="device-number" className="block">
                {t(
                  device.isCard
                    ? "onboarding.form.card-device-number"
                    : "onboarding.form.wearable-device-number"
                )}
              </label>
              <input
                id="device-number"
                type="text"
                className={`w-full + ${
                  (formError === "DEVICE_NUMBER" ||
                    formError === "CARD_NUMBER" ||
                    formError === "DEVICE_UNKNOWN") &&
                  "border border-red-500"
                }`}
                placeholder={device.isCard ? "12345678" : "1234567"}
                required
              />
              {(formError === "DEVICE_NUMBER" ||
                formError === "CARD_NUMBER" ||
                formError === "DEVICE_UNKNOWN") && (
                <p className="mt-2 text-sm text-red-500">
                  {t(formErrors[formError])}
                </p>
              )}
              <p className="text-sm leading-5 text-app-grey-light">
                {t(
                  device.isCard
                    ? "onboarding.form.card-desc"
                    : "onboarding.form.wearable-desc"
                )}
              </p>
            </fieldset>
            {device.activationCodeRequired && (
              <fieldset className="space-y-1.5">
                <label htmlFor="activation-code" className="block">
                  {t("onboarding.form.activation-code")}
                </label>
                <input
                  id="activation-code"
                  type="text"
                  className={`w-full + ${
                    formError === "INVALID_CODE" && "border border-red-500"
                  }`}
                  pattern="[0-9a-zA-Z]{5}" // This enforces a 6-digit numeric pattern
                  placeholder={"ABCDE"}
                  required
                />
                {formError === "INVALID_CODE" && (
                  <p className="mt-2 text-sm text-red-500">
                    {t(formErrors[formError])}
                  </p>
                )}
                <p className="text-sm leading-5 text-app-grey-light">
                  {t("onboarding.form.activation-desc")}
                </p>
              </fieldset>
            )}
            <fieldset className="space-y-1.5">
              <label htmlFor="email" className="block">
                {t("onboarding.form.email")}
              </label>
              <input
                id="email"
                type="email"
                className={`w-full + ${
                  (formError === "EMAIL_REQUIRED" ||
                    formError === "EMAIL_MATCH" ||
                    formError === "INVALID_EMAIL") &&
                  "border border-red-500"
                }`}
                placeholder="john.doe@email.com"
                required
              />
              {(formError === "EMAIL_REQUIRED" ||
                formError === "INVALID_EMAIL") && (
                <p className="mt-2 text-sm text-red-500">
                  {t(formErrors[formError])}
                </p>
              )}
              <p className="text-sm leading-5 text-app-grey-light">
                {t(
                  device.isCard
                    ? "onboarding.form.email-desc-card"
                    : "onboarding.form.email-desc-wearable"
                )}
              </p>
            </fieldset>
            <fieldset className="space-y-1.5">
              <label htmlFor="confirm-email" className="block">
                {t("onboarding.form.confirm-email")}
              </label>
              <input
                id="confirm-email"
                type="email"
                className={`w-full + ${
                  (formError === "EMAIL_REQUIRED_CONFIRM" ||
                    formError === "EMAIL_MATCH") &&
                  "border border-red-500"
                }`}
                placeholder="john.doe@email.com"
                required
              />
              {(formError === "EMAIL_REQUIRED_CONFIRM" ||
                formError === "EMAIL_MATCH") && (
                <p className="mt-2 text-sm text-red-500">
                  {t(formErrors[formError])}
                </p>
              )}
            </fieldset>
            <div className="flex items-center justify-center !mt-10">
              <button
                type="submit"
                className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-emdium leading-5"
              >
                {t("onboarding.form.submit")}
              </button>
            </div>
          </form>
        </div>
      </section>
      <LanguageModal
        isFirstTime={isFirstTime}
        setIsFirstTime={setIsFirstTime}
        setIsModalInfo={setIsModalInfo}
        device={device}
      />
      <InfoModal
        isInfoModal={isModalInfo}
        setIsModalInfo={setIsModalInfo}
        isCard={device.isCard}
      />
    </>
  );
};

export default Onboarding;

const lang = [
  { name: "English", code: "en" },
  { name: "Français", code: "fr" },
  { name: "Español", code: "es" },
  { name: "Deutsch", code: "de" },
  { name: "Português", code: "pt" },
  { name: "Polski", code: "pl" },
];

const LanguageModal = ({
  isFirstTime,
  setIsFirstTime,
  setIsModalInfo,
  device,
}) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    t("language").toLowerCase()
  );
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Transition appear show={isFirstTime} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setIsFirstTime(false);
          setIsModalInfo(true);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[487px] transform rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all space-y-6">
                <Dialog.Title
                  as="h1"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t(
                    device.isCard
                      ? "onboarding.modal.title-card"
                      : "onboarding.modal.title-wearable"
                  )}
                </Dialog.Title>

                <div className="space-y-2">
                  <label className="font-semibold leading-6">
                    {t("onboarding.modal.label")}
                  </label>

                  <Listbox
                    value={selectedLanguage}
                    onChange={(e) => {
                      setSelectedLanguage(e);
                      handleLanguageChange(e);
                    }}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="border border-app-grey-lighter relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-app-blue-visa-light text-sm md:text-base leading-5">
                        <span
                          className={`block truncate ${
                            selectedLanguage
                              ? "text-app-grey"
                              : "text-app-grey-light"
                          }`}
                        >
                          {selectedLanguage
                            ? lang.find(
                                (item) => item.code === selectedLanguage
                              ).name
                            : "Choose your language"}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <GoChevronDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {lang.map((item) => (
                            <Listbox.Option
                              key={item.code}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 px-4 ${
                                  active
                                    ? "bg-app-blue-light text-app-blue-visa"
                                    : "text-gray-900"
                                }`
                              }
                              value={item.code}
                            >
                              <span className={`block truncate`}>
                                {item.name}
                              </span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>

                <div className="flex items-center justify-center">
                  <button
                    className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-emdium leading-5"
                    onClick={() => {
                      setIsFirstTime(false);
                      setIsModalInfo(true);
                    }}
                  >
                    {t("onboarding.modal.confirm")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const InfoModal = ({ isInfoModal, setIsModalInfo, isCard, amount }) => {
  const { t } = useTranslation();
  const informations = [
    {
      desc: isCard
        ? t("onboarding.info-modal.desc1-card")
        : t("onboarding.info-modal.desc1-wearable"),
      img: Info1Img,
    },
    {
      desc: isCard
        ? t("onboarding.info-modal.desc2-card")
        : t("onboarding.info-modal.desc2-wearable"),
      img: Info2Img,
    },
    {
      desc: isCard
        ? t("onboarding.info-modal.desc3-card")
        : t("onboarding.info-modal.desc3-wearable"),
      img: Info3Img,
    },
  ];

  return (
    <Transition appear show={isInfoModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalInfo(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[487px] transform rounded-2xl bg-white md:p-6 p-3 text-left align-middle shadow-xl transition-all md:space-y-6 space-y-3">
                {informations.map((info, i) => (
                  <div
                    className={`flex items-center gap-4 bg-app-blue-light p-4 rounded-2xl ${
                      i % 2 === 0 ? "flex-row-reverse" : "flex-row"
                    }`}
                    key={i}
                  >
                    <p className="text-sm md:text-base text-app-grey text-center font-medium leading-6 flex-1">
                      {info.desc}
                    </p>
                    <div className="w-[6.25rem] h-[6.25rem] rounded-lg overflow-hidden shrink-0 block bg-white p-2.5">
                      <img
                        src={info.img}
                        alt=""
                        className="w-full h-full object-cover object-center aspect-square"
                      />
                    </div>
                  </div>
                ))}

                <div className="flex items-center justify-center">
                  <button
                    className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-emdium leading-5"
                    onClick={() => setIsModalInfo(false)}
                  >
                    {t("onboarding.info-modal.confirm")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
