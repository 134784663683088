import {
  AiFillCheckCircle,
  AiOutlineFrown,
  AiOutlineWarning,
} from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

const CustomToast = ({ toast, title, description, status = "SUCCESS" }) => {
  return (
    <div className="bg-white flex items-center shadow-app rounded border border-app-grey-lighter/50">
      <div
        className={`px-3 h-full  grid place-items-center ${
          status === "SUCCESS"
            ? "bg-app-green/10"
            : status === "WARNING"
            ? "bg-[#FFDC5F]"
            : "bg-app-red/50"
        }`}
      >
        {status === "SUCCESS" && (
          <AiFillCheckCircle size={24} className="text-app-green" />
        )}
        {status === "WARNING" && (
          <AiOutlineWarning size={24} className="text-app-[#EF8D2B]" />
        )}
        {status.includes("ERROR") && (
          <AiOutlineFrown size={24} className="text-app-red" />
        )}
      </div>
      <div className="p-4 flex gap-x-4">
        <div className="space-y-1 flex-1">
          <p className="font-bold leading-6 text-black">{title}</p>
          <p className="text-sm leading-5 text-black">{description}</p>
          {status === "GENERIC_ERROR" && (
            <a
              href="https://vportal.zendesk.com"
              target="_blank"
              rel="noreferrer"
              className="text-sm leading-5 text-app-blue underline"
            >
              support
            </a>
          )}
        </div>
        <IoMdClose
          size={20}
          onClick={() => toast.remove(toast.id)}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};
export default CustomToast;
