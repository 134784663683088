import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { MailTOS } from "../../assets/mail-tos";
import API from "../../services/api";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";
import CustomToast from "../../components/CustomToast";

const TermsOfUse = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      const bottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 50;
      setIsButtonEnabled(bottom);
    };

    handleScroll();

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const uniqueHash = location.hash.substring(1);
      if (!uniqueHash) {
        toast.error("Device not recognized. Please scan the QR code again.");
        return;
      }

      const res = await API.post("/user/cgu", {
        CGUaccepted: true,
      });
      if (!res.ok) {
        setLoading(false);
        if (res.errorCode === "WAIT_BEFORE_RETRY")
          return toast.custom(
            () => (
              <CustomToast
                toast={toast}
                description={t("generic-error.description-retry")}
                status="WARNING"
              />
            ),
            {
              position: "top-right",
            }
          );
        return toast.custom(
          () => (
            <CustomToast
              toast={toast}
              title={t("generic-error.title")}
              description={t("generic-error.description")}
              status="GENERIC_ERROR"
            />
          ),
          {
            position: "top-right",
          }
        );
      }

      navigate(`/wallet#${uniqueHash}`, {
        replace: true,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  if (loading) return <Loader />;

  return (
    <section className="md:my-6 md:bg-white rounded-2xl md:shadow-app  max-w-screen-md mx-auto flex flex-col md:flex-row items-center justify-center gap-10 md:p-10 p-6">
      <div className="hidden md:block">
        <MailTOS />
      </div>
      <div className="flex-1 md:border-l md:pl-10 flex flex-col md:gap-y-6 gap-4 w-full">
        <h1 className="text-2xl leading-8 font-semibold">{t("tos.title")}</h1>
        <div
          className="md:max-h-[20rem] max-h-[calc(100dvh-7.5rem-3rem-3.5rem)] h-full overflow-y-auto pr-2.5 flex-1"
          ref={containerRef}
        >
          <p className="text-sm leading-5 whitespace-pre-line" id="tos">
            {t("tos.content")}
          </p>
        </div>
        <div className="flex items-center justify-end gap-6">
          <button
            onClick={handleSubmit}
            disabled={!isButtonEnabled}
            className="bg-app-blue-visa-light py-2.5 px-6 rounded-lg text-white text-sm font-medium leading-5 flex-1 md:flex-none text-center disabled:bg-app-grey-light"
          >
            {t("tos.action.agree")}
            <AiOutlineLoading3Quarters
              className={loading ? "animate-spin ml-2" : "hidden"}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
