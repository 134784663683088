import { Fragment, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import "swiper/css";

import API from "../../services/api";
import CustomToast from "../../components/CustomToast";
import { maskEmail } from "../../utils";

require("dayjs/locale/en");
require("dayjs/locale/fr");

export const BlockModal = ({
  isShowingBlock,
  setIsShowingBlock,
  reloadWallets,
  deviceNumber,
  email,
  deviceType,
}) => {
  const { t } = useTranslation();

  return (
    <Transition appear show={isShowingBlock} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsShowingBlock(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white md:p-6 p-4 text-left align-middle shadow-xl transition-all space-y-4">
                <Dialog.Title
                  as="h3"
                  className="md:text-lg text-base font-medium leading-6 text-gray-900"
                >
                  {deviceType === "CARD"
                    ? t("wallet.blockModal.title-card")
                    : t("wallet.blockModal.title-wearable")}
                </Dialog.Title>
                <p className="leading-5 text-sm">
                  {deviceType === "CARD"
                    ? t("wallet.blockModal.description-card")
                    : t("wallet.blockModal.description-wearable")}
                  <b>
                    {t("wallet.blockModal.description2", {
                      email: maskEmail(email),
                    })}
                  </b>
                </p>
                <div className="flex items-center justify-end gap-x-2">
                  <button
                    className="text-sm leading-5 text-app-blue font-medium py-2.5 px-4"
                    onClick={() => setIsShowingBlock(false)}
                  >
                    {t("wallet.blockModal.cancel")}
                  </button>
                  <button
                    className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-medium leading-5"
                    onClick={async () => {
                      const res = await API.post("/device/block", {
                        deviceNumber,
                      });
                      if (!res.ok)
                        return toast.custom(
                          () => (
                            <CustomToast
                              toast={toast}
                              title={t("generic-error.title")}
                              description={t("generic-error.description")}
                              status="GENERIC_ERROR"
                            />
                          ),
                          {
                            position: "top-right",
                          }
                        );
                      await reloadWallets();
                      setIsShowingBlock(false);
                    }}
                  >
                    {deviceType === "CARD"
                      ? t("wallet.blockModal.confirm-card")
                      : t("wallet.blockModal.confirm-wearable")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const UnblockModal = ({
  isShowingUnblock,
  setIsShowingUnblock,
  email,
  unblock,
}) => {
  const [counter, setCounter] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    if (counter > 0) {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      // Clear the interval after 5 seconds (adjust as needed)
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 10000);

      // Clean up the interval when the component unmounts or when the action is undone
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [counter]);

  const handleResend = async (e) => {
    setCounter(10);
    e.preventDefault();
    unblock();

    toast.custom(
      () => (
        <CustomToast
          toast={toast}
          title={t("wallet.unblockModal.notification.title")}
          description={t("wallet.unblockModal.notification.description", {
            email: maskEmail(email),
          })}
        />
      ),
      {
        position: "top-right",
      }
    );
  };
  return (
    <Transition appear show={isShowingUnblock} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsShowingUnblock(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white md:p-10 p-6 text-left align-middle shadow-xl transition-all space-y-6 relative">
                <Dialog.Title
                  as="h3"
                  className="md:text-2xl text-lg font-medium leading-6 text-gray-900"
                >
                  {t("wallet.unblockModal.title")}
                </Dialog.Title>
                <p className="leading-5 text-sm md:text-base">
                  {t("wallet.unblockModal.description", {
                    email: maskEmail(email),
                  })}
                </p>
                <p className="text-sm md:text-base">
                  {t("wallet.unblockModal.didNotRecieve")}{" "}
                  {counter > 0 ? (
                    <p>
                      {t("wallet.unblockModal.waiting-resend", { counter })}
                    </p>
                  ) : (
                    <button
                      onClick={handleResend}
                      className="text-app-blue underline cursor-pointer"
                    >
                      {t("wallet.unblockModal.resend")}
                    </button>
                  )}
                </p>

                <button
                  className="absolute top-0 right-6"
                  onClick={() => setIsShowingUnblock(false)}
                >
                  <IoMdClose size={20} />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export const UnblockModalBlockedUser = ({
  isShowingUnblock,
  setIsShowingUnblock,
  reloadWallets,
  deviceNumber,
  email,
  deviceType,
}) => {
  const { t } = useTranslation();

  return (
    <Transition appear show={isShowingUnblock} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsShowingUnblock(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white md:p-6 p-4 text-left align-middle shadow-xl transition-all space-y-4">
                <Dialog.Title
                  as="h3"
                  className="md:text-lg text-base font-medium leading-6 text-gray-900"
                >
                  {deviceType === "CARD"
                    ? t("wallet.blockedUserModal.title-card")
                    : t("wallet.blockedUserModal.title-wearable")}
                </Dialog.Title>
                <p className="leading-5 text-sm">
                  {deviceType === "CARD"
                    ? t("wallet.blockedUserModal.description-card")
                    : t("wallet.blockedUserModal.description-wearable")}
                </p>
                <div className="flex items-center justify-end gap-x-2">
                  <button
                    className="text-sm leading-5 text-app-blue font-medium py-2.5 px-4"
                    onClick={() => setIsShowingUnblock(false)}
                  >
                    {t("wallet.blockedUserModal.cancel")}
                  </button>
                  <a
                    className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-medium leading-5"
                    href="https://vportal.zendesk.com"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setIsShowingUnblock(false)}
                  >
                    {t("wallet.blockedUserModal.contact")}
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
