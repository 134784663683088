import { Fragment, useState, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Transition, Combobox } from "@headlessui/react";

import listCountry from "../../assets/list-country.json";
import LeftImage from "../../assets/onboarding/info-3.png";
import API from "../../services/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import CustomToast from "../../components/CustomToast";
const nameRegex = /^[A-zÀ-ú-\s]+$/;

const formErrors = {
  FIRST_NAME_REQUIRED: "profile.form.validation.firstName",
  LAST_NAME_REQUIRED: "profile.form.validation.lastName",
  COUNTRY_REQUIRED: "profile.form.validation.country",
  FIRST_NAME_INVALID: "profile.form.validation.special-characters",
  LAST_NAME_INVALID: "profile.form.validation.special-characters",
};

const Profile = () => {
  const [values, setValues] = useState({});
  const [query, setQuery] = useState("");
  const [formError, setFormError] = useState();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { device } = useSelector((state) => state.Auth);

  const comboxBoxButtonRef = useRef(null);

  const filteredCountry = listCountry.filter((country) =>
    country.country.toLowerCase().includes(query.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const uniqueHash = location.hash.substring(1);
      if (!uniqueHash) {
        toast.error("Device not recognized. Please scan the QR code again.");
        return;
      }

      if (!values.firstName) {
        return setFormError("FIRST_NAME_REQUIRED");
      }

      if (!nameRegex.test(values.firstName)) {
        return setFormError("FIRST_NAME_INVALID");
      }

      if (!values.lastName) {
        return setFormError("LAST_NAME_REQUIRED");
      }

      if (!nameRegex.test(values.lastName)) {
        return setFormError("LAST_NAME_INVALID");
      }

      if (!values.country) {
        return setFormError("COUNTRY_REQUIRED");
      }
      const res = await API.post(`/user/signup_profile`, {
        firstName: values.firstName,
        lastName: values.lastName,
        country: listCountry.find(
          (country) => country.country === values.country
        ).isoCode,
      });
      if (!res.ok)
        return toast.custom(
          () => (
            <CustomToast
              toast={toast}
              title={t("generic-error.title")}
              description={t("generic-error.description")}
              status="GENERIC_ERROR"
            />
          ),
          {
            position: "top-right",
          }
        );
      if (res.ok) {
        navigate(`/onboarding/terms-of-use#${uniqueHash}`, {
          replace: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="md:my-6 md:bg-white rounded-2xl md:shadow-app  max-w-screen-md mx-auto flex flex-col md:flex-row items-center justify-center gap-10 md:p-10 p-6">
      <div className="flex-1 flex-col items-center justify-center md:gap-y-10 gap-y-6 hidden md:flex">
        <img alt="" src={LeftImage} className="rounded-lg w-[14.813rem]" />
        <h2 className="font-bold leading-6 text-center">
          {t(
            device.type === "CARD"
              ? "profile.card-image-caption"
              : "profile.wearable-image-caption"
          )}
        </h2>
      </div>
      <div className="flex-1 md:border-l md:pl-10 flex flex-col md:gap-y-10 gap-y-6">
        <div className="md:space-y-2 space-y-1.5">
          <h1 className="text-2xl leading-8 font-semibold">
            {t("profile.title")}
          </h1>
          <p className="text-sm leading-5">
            {t(
              device.type === "CARD"
                ? "profile.card-description"
                : "profile.wearable-description"
            )}
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="space-y-4 flex-1 flex flex-col"
          noValidate
        >
          <fieldset className="space-y-1.5">
            <label htmlFor="first-name" className="block">
              {t("profile.form.firstName")}
            </label>
            <input
              id="first-name"
              type="text"
              className={`w-full + ${
                (formError === "FIRST_NAME_REQUIRED" ||
                  formError === "FIRST_NAME_INVALID") &&
                "border border-red-500"
              }`}
              placeholder="John"
              onChange={(e) =>
                setValues({ ...values, firstName: e.target.value })
              }
              required
            />
            {(formError === "FIRST_NAME_REQUIRED" ||
              formError === "FIRST_NAME_INVALID") && (
              <p className="mt-2 text-sm text-red-500">
                {t(formErrors[formError])}
              </p>
            )}
          </fieldset>
          <fieldset className="space-y-1.5">
            <label htmlFor="last-name" className="block">
              {t("profile.form.lastName")}
            </label>
            <input
              id="last-name"
              type="text"
              className={`w-full + ${
                (formError === "LAST_NAME_REQUIRED" ||
                  formError === "LAST_NAME_INVALID") &&
                "border border-red-500"
              }`}
              placeholder="Doe"
              onChange={(e) =>
                setValues({ ...values, lastName: e.target.value })
              }
            />
            {(formError === "LAST_NAME_REQUIRED" ||
              formError === "LAST_NAME_INVALID") && (
              <p className="mt-2 text-sm text-red-500">
                {t(formErrors[formError])}
              </p>
            )}
          </fieldset>
          <div className="space-y-1.5">
            <label className="font-medium leading-6">
              {t("profile.form.country.label")}
            </label>

            <Combobox
              value={values?.country}
              onChange={(e) => {
                setValues({ ...values, country: e });
                setQuery(e);
              }}
            >
              <div className="relative">
                <Combobox.Input
                  className={`w-full + ${
                    formError === "COUNTRY_REQUIRED" && "border border-red-500"
                  }`}
                  placeholder={t("profile.form.country.placeholder")}
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  required
                  autoComplete="off"
                  onClick={() => comboxBoxButtonRef.current?.click()}
                />
                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                  ref={comboxBoxButtonRef}
                >
                  <BiChevronDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {filteredCountry.map((country) => (
                      <Combobox.Option
                        key={country.isoCode}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 px-4 ${
                            active
                              ? "bg-app-blue-light text-app-blue-visa"
                              : "text-gray-900"
                          }`
                        }
                        value={country.country}
                      >
                        <span className={`block truncate`}>
                          <span className="mr-3">{country.emojiFlag}</span>

                          {country.country}
                        </span>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
            {formError === "COUNTRY_REQUIRED" && (
              <p className="mt-2 text-sm text-red-500">
                {t(formErrors[formError])}
              </p>
            )}
          </div>
          <div className="flex items-center justify-center !mt-10">
            <button
              type="submit"
              className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-emdium leading-5"
            >
              {t("profile.form.next")}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Profile;
