import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useNavigate,
  Outlet,
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { setDevice, setUser } from "./redux/auth/actions";
import i18n from "./i18n";

import Loader from "./components/Loader";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import API from "./services/api";

import Onboarding from "./scenes/onboarding/onboarding";
import TermsOfUse from "./scenes/onboarding/termsOfUse";
import Profile from "./scenes/onboarding/profile";
import Wallet from "./scenes/wallet/wallet";
import NotFound from "./components/notFound";

const VerifyHash = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.title = "Olympic Games - Money Walkie";
    async function fetchData() {
      await new Promise((resolve) => setTimeout(resolve, 100));

      try {
        let uniqueHash = location.hash.substring(1);
        const searchParams = location.search;
        if (!uniqueHash) uniqueHash = location.pathname.split("/")[1];
        if (!uniqueHash) throw new Response("Not Found", { status: 404 }); // no hash. redirect to support or to error page
        API.setUniqueHash(uniqueHash);

        const res = await API.get(`/user/verify_hash`);
        if (!res.ok) {
          setError(res.errorCode);
          throw new Response("Not Found", {
            status: 404,
            errorCode: res.errorCode,
          }); // wrong hash. redirect to support or to error page
        }
        if (res.data?.user)
          i18n.changeLanguage(res.data.user.language.toLowerCase());

        switch (res.data?.step) {
          case "ONBOARDING":
            if (!res.data?.device)
              throw new Response("Not Found", { status: 404 });
            dispatch(setDevice(res.data.device));

            navigate(`/onboarding#${uniqueHash}`, {
              replace: true,
            });
            break;

          case "SIGNUP":
            if (!res.data?.device || !res.data?.user)
              throw new Response("Not Found", { status: 404 });
            dispatch(setDevice(res.data.device));
            dispatch(setUser(res.data.user));

            navigate(`/onboarding/profile#${uniqueHash}`, {
              replace: true,
            });
            break;

          case "CGU":
            if (!res.data?.device || !res.data?.user)
              throw new Response("Not Found", { status: 404 });
            dispatch(setDevice(res.data.device));
            dispatch(setUser(res.data.user));

            navigate(`/onboarding/terms-of-use#${uniqueHash}`, {
              replace: true,
            });
            break;

          case "WALLET":
            if (!res.data?.user)
              throw new Response("Not Found", { status: 404 });
            dispatch(setUser(res.data.user));

            navigate(`/wallet${searchParams}#${uniqueHash}`, { replace: true });
            break;

          default:
            throw new Response("Not Found", { status: 404 });
        }
      } catch (error) {
        console.log("error", error);
        navigate("/", { replace: true });
      }
      setLoading(false);
    }

    fetchData();
  }, [dispatch, navigate]);

  if (location.pathname === "/" && location.hash === "")
    return <NotFound isServiceAvailable={error !== "SERVICE_UNAVAILABLE"} />;

  if (loading) return <Loader />;

  return <Outlet />;
};

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <BrowserRouter>
        <Header />
        <div className="flex-grow">
          <Routes>
            <Route element={<VerifyHash />}>
              <Route path="onboarding">
                <Route index element={<Onboarding />} />
                <Route path="profile" element={<Profile />} />
                <Route path="terms-of-use" element={<TermsOfUse />} />
              </Route>

              <Route path="wallet" element={<Wallet />} />

              <Route index path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
