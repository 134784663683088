export const VisaLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="29"
      fill="none"
      viewBox="0 0 87 29"
      {...props}
    >
      <path
        fill="#1A1F71"
        fillRule="evenodd"
        d="M44.808 9.146c-.04 3.89 3.47 6.077 6.117 7.36 2.728 1.325 3.65 2.187 3.63 3.37-.02 1.826-2.165 2.608-4.191 2.648-3.51.06-5.556-.943-7.18-1.705l-1.264 5.937c1.624.742 4.653 1.404 7.782 1.444 7.361 0 12.155-3.63 12.195-9.266.02-7.14-9.888-7.542-9.808-10.73.02-.964.943-2.007 2.968-2.267 1.003-.14 3.771-.241 6.9 1.203l1.224-5.736C61.495.782 59.33.2 56.62.2c-6.899 0-11.773 3.69-11.813 8.946zM75.014.702c-1.343 0-2.467.782-2.988 1.985L61.516 27.76h7.34l1.465-4.032h8.986l.842 4.032h6.499L80.99.702h-5.977zm1.023 7.32l2.126 10.17h-5.816l3.69-10.17zM35.903.703l-5.797 27.057h7L42.903.702h-7zm-10.35 0l-7.3 18.432-2.95-15.664C14.964 1.725 13.6.702 12.075.702H.16L0 1.484c2.447.521 5.215 1.384 6.9 2.306 1.023.562 1.323 1.043 1.664 2.387L14.14 27.78h7.401L32.895.702h-7.341z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
