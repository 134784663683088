export const authActions = {
  SETUSER: "SETUSER",
  SETDEVICE: "SETDEVICE",
};

export function setUser(user) {
  return { type: authActions.SETUSER, user };
}

export function setDevice(device) {
  return { type: authActions.SETDEVICE, device };
}
