export const MailTOS = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
      {...props}
    >
      <g
        stroke="#1A1F71"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.8"
      >
        <path d="M1.25 23.75h17.5"></path>
        <path d="M7.5 31.25h11.25"></path>
        <path d="M13.75 38.75h5"></path>
        <path d="M58.75 18.75h-35v27.5h35v-27.5z"></path>
        <path d="M23.75 18.75l17.5 15 17.5-15"></path>
      </g>
    </svg>
  );
};
