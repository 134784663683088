export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function currencyToSymbol(currency) {
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return "€";
  }
}

export function maskEmail(email) {
  const parts = email.split("@");
  const username = parts[0];
  const domain = parts[1];
  const maskedUsername =
    username.substring(0, 2) + "*".repeat(Math.min(6, username.length - 2));
  const maskedEmail = maskedUsername + "@" + domain;

  return maskedEmail;
}
