import { Fragment, useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { IoArrowUpOutline, IoAlertSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import "swiper/css";
import API from "../../services/api";
import { currencyToSymbol } from "../../utils";
import dayjs from "dayjs";
import Loader from "../../components/Loader";
import { toast } from "react-hot-toast";
import CustomToast from "../../components/CustomToast";
require("dayjs/locale/en");
require("dayjs/locale/fr");

export const BlockModal = ({
  isShowingBlock,
  setIsShowingBlock,
  reloadWallets,
  deviceNumber,
}) => {
  const { t } = useTranslation();
  return (
    <Transition appear show={isShowingBlock} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsShowingBlock(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white md:p-6 p-4 text-left align-middle shadow-xl transition-all space-y-4">
                <Dialog.Title
                  as="h3"
                  className="md:text-lg text-base font-medium leading-6 text-gray-900"
                >
                  {t("wallet.blockModal.title")}
                </Dialog.Title>
                <p className="leading-5 text-sm md:text-base">
                  {t("wallet.blockModal.description")}
                </p>
                <div className="flex items-center justify-end gap-x-2">
                  <button
                    className="text-sm leading-5 text-app-blue font-medium py-2.5 px-4"
                    onClick={() => setIsShowingBlock(false)}
                  >
                    {t("wallet.blockModal.cancel")}
                  </button>
                  <button
                    className="py-2.5 px-6 rounded-lg bg-app-blue-visa-light text-white text-sm font-medium leading-5"
                    onClick={async () => {
                      const res = await API.post("/device/block", {
                        deviceNumber,
                      });
                      if (!res.ok)
                        return toast.custom(
                          () => (
                            <CustomToast
                              toast={toast}
                              title={t("generic-error.title")}
                              description={t("generic-error.description")}
                              status="GENERIC_ERROR"
                            />
                          ),
                          {
                            position: "top-right",
                          }
                        );
                      await reloadWallets();
                      setIsShowingBlock(false);
                    }}
                  >
                    {t("wallet.blockModal.confirm")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const UnblockModal = ({
  isShowingUnblock,
  setIsShowingUnblock,
  email,
  unblock,
}) => {
  const { t } = useTranslation();
  return (
    <Transition appear show={isShowingUnblock} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsShowingUnblock(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white md:p-10 p-6 text-left align-middle shadow-xl transition-all space-y-6 relative">
                <Dialog.Title
                  as="h3"
                  className="md:text-lg text-base font-medium leading-6 text-gray-900"
                >
                  {t("wallet.unblockModal.title")}
                </Dialog.Title>
                <p className="leading-5 text-sm md:text-base">
                  {t("wallet.unblockModal.description", { email })}
                </p>
                <p className="text-sm">
                  {t("wallet.unblockModal.didNotRecieve")}{" "}
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      unblock();
                      setIsShowingUnblock(false);
                    }}
                    className="text-app-blue underline cursor-pointer"
                  >
                    {t("wallet.unblockModal.resend")}
                  </a>
                </p>

                <button
                  className="absolute top-0 right-6"
                  onClick={() => setIsShowingUnblock(false)}
                >
                  <IoMdClose size={20} />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const TransactionsList = ({ walletId }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(`/transaction?walletId=${walletId}`);
      if (!res.ok)
        return toast.custom(
          () => (
            <CustomToast
              toast={toast}
              title={t("generic-error.title")}
              description={t("generic-error.description")}
              status="GENERIC_ERROR"
            />
          ),
          {
            position: "top-right",
          }
        );

      setTransactions(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [walletId]);
  if (loading) return <Loader size={0.05} responsive="true" />;

  return (
    <>
      {transactions.map((transaction) => (
        <TransactionItem key={transaction.id} item={transaction} />
      ))}
    </>
  );
};

const TransactionItem = ({ item }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div className="flex items-center gap-4">
        <div className="bg-app-blue-visa w-10 h-10 rounded-[16px] grid place-items-center">
          {item.status === "ACCEPTED" ||
          item.status === "AUTHORIZED" ||
          item.status === "PENDING" ? (
            <IoArrowUpOutline
              size={24}
              className={`text-app-gold-visa 
            ${
              item.type === "PAYIN"
                ? "transform rotate-180"
                : "transform rotate-45"
            } `}
            />
          ) : (
            <IoAlertSharp size={24} className="text-app-red" />
          )}
        </div>
        <div className="font-codec flex-1 min-w-0">
          <p className="leading-5 font-light overflow-hidden whitespace-nowrap text-ellipsis">
            {item.merchantName}
          </p>
          <p className="leading-4 font-light text-sm text-app-grey-light">
            {dayjs(item.createdAt)
              .locale(i18n.language)
              .format("DD MMMM YYYY, HH:mm")}
          </p>
        </div>
        <p
          className={`rounded-full py-0.5 px-2 text-xs leading-[1.125rem] bg-white text-app-grey`}
        >
          {item.type === "PAYIN" ? "+" : "-"} {item.amount}{" "}
          {currencyToSymbol(item.currency)}
        </p>
      </div>
      <div className="h-px w-full bg-black/10 my-2" />
    </>
  );
};
