import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { GoChevronDown } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { IoHelpCircleOutline } from "react-icons/io5";

import { VisaLogo } from "../assets/visa-logo";
import i18n from "../i18n";
import API from "../services/api";

export const Header = () => {
  const { t } = useTranslation();
  const handleLanguageChange = async (lang) => {
    i18n.changeLanguage(lang);
    await API.put(`/user`, { language: lang });
  };

  return (
    <>
      <header className="md:p-6 p-5 flex items-center justify-between shadow-app md:shadow-none">
        <VisaLogo className="md:w-auto md:h-auto h-5" />
        <div className="flex md:gap-4 gap-4">
          <a
            href="https://vportal.zendesk.com/hc/fr"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row gap-0.5 items-center justify-center gap-y-1 w-[3.75rem] hover:underline"
          >
            <IoHelpCircleOutline size={14} />
            <p className="text-sm leading-4">{t("wallet.help")}</p>
          </a>
          <Menu
            as="div"
            className="relative inline-block text-left text-app-grey text-sm font-medium leading-5"
          >
            <Menu.Button className="flex items-center gap-x-4">
              <span>{t("language")}</span>
              <GoChevronDown />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-10">
                <Menu.Item>
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => handleLanguageChange("en")}
                  >
                    English
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => handleLanguageChange("fr")}
                  >
                    Français
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => handleLanguageChange("de")}
                  >
                    Deutsch
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => handleLanguageChange("es")}
                  >
                    Español
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => handleLanguageChange("pt")}
                  >
                    Português
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => handleLanguageChange("pl")}
                  >
                    Polski
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
      <Outlet />
    </>
  );
};
