import "isomorphic-fetch";

import { API_URL } from "../config";

class api {
  constructor() {
    this.uniqueHash = "";
  }

  getUniqueHash() {
    return this.uniqueHash;
  }

  setUniqueHash(uniqueHash) {
    this.uniqueHash = uniqueHash;
  }

  async toastWrapper(fetchFunction) {
    const res = await fetchFunction();
    const response = await res.json();
    response.status = res.status;
    return response;
  }

  get(path, params = null) {
    let url = `${API_URL}${path}`;
    if (params) url += `?${new URLSearchParams(params)}`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.toastWrapper(() =>
          fetch(url, {
            mode: "cors",
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${this.uniqueHash}`,
              "User-Agent": "MoneyWalkie",
            },
          })
        );

        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  put(path, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.toastWrapper(() =>
          fetch(`${API_URL}${path}`, {
            mode: "cors",
            method: "PUT",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${this.uniqueHash}`,
            },
            body: typeof body === "string" ? body : JSON.stringify(body),
          })
        );

        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  putFormData(path, formData) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.toastWrapper(() =>
          fetch(`${API_URL}${path}`, {
            mode: "cors",
            method: "PUT",
            credentials: "include",
            headers: { Authorization: `${this.uniqueHash}` },
            body: formData,
          })
        );

        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  postFormData(path, formData) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          credentials: "include",
          method: "POST",
          headers: { Authorization: `${this.uniqueHash}` },
          body: formData,
        });
        const res = await response.json();
        resolve(res);
      } catch (e) {
        console.log("e", e);
        reject(e);
      }
    });
  }

  remove(path, params = null) {
    let url = `${API_URL}${path}`;
    if (params) url += `?${new URLSearchParams(params)}`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.toastWrapper(() =>
          fetch(url, {
            mode: "cors",
            credentials: "include",
            method: "DELETE",
            headers: {
              Authorization: `${this.uniqueHash}`,
            },
          })
        );

        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  post(path, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.toastWrapper(() =>
          fetch(`${API_URL}${path}`, {
            mode: "cors",
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${this.uniqueHash}`,
            },
            body: typeof body === "string" ? body : JSON.stringify(body),
          })
        );

        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  download(path, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.toastWrapper(() =>
          fetch(`${API_URL}${path}`, {
            mode: "cors",
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${this.uniqueHash}`,
            },
            body: typeof body === "string" ? body : JSON.stringify(body),
          })
        );

        if (response.status !== 200) {
          return reject(response);
        }
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}

const API = new api();
export default API;
