import React from "react";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="mt-auto p-5 flex items-center justify-center text-center">
      <p className="text-sm leading-5 text-app-grey-light">
        {t("footer.poweredBy")}
        <a
          href="https://moneywalkie.com/?utm_source=visa&utm_medium=app&utm_campaign=lot1"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          MoneyWalkie
        </a>
        , {t("footer.distributor")}
        <a
          href="https://xpollens.com"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          Xpollens
        </a>
      </p>
    </footer>
  );
};
